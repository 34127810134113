.dialog-footer[data-v-01d37c8c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 12px 0;
  border-top: 1px solid rgb(204, 204, 204);
}
.iconLine[data-v-01d37c8c] {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}
[data-v-01d37c8c] .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.borderLine[data-v-01d37c8c] {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
.formBox[data-v-01d37c8c] {
  overflow: auto;
  overflow-x: hidden;
  margin-left: 6px;
  font-size: 24px;
  padding: 4px 0;
}